import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion, useCycle } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useLocation, useHistory } from 'react-router-dom';
import placeholder from '../../../images/svg/placeholder.svg';


const ActivitiesTechnicalAdvice = ( prevPath ) => {


  // consume theme context
  const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)
  const [exitAnimation, cycleExitAnimation] = useCycle("exitLeft", "exitBottom", "exitRight", "exit")

  // consume language context
  const { current, de, en } = useContext(LanguageContext);
  // setting language
  const lang = (current === "de") ? de : en 
  // get location
  const location = useLocation()
  const history = useHistory()
  // let exitString = "exit";
  let initString = "hidden";
    
  // useEffect(() => {
    // console.log(prevPath.location.state.prevPath
    if(history.location.state){
      if(history.location.state.from == 'left'){
        if(initString != "enterLeft") initString = "enterLeft";
      }else if(history.location.state.from == 'right'){
        if(initString != "enterRight") initString = "enterRight";
      }
    }
  // }, []);
  
  // loop through subpagelinks and see if immediate neighbor is prevpath.location...:
  if(prevPath.location.state){
    for (let index = 0; index < subpagelinks[location.pathname].length; index++) {
      const element = subpagelinks[location.pathname][index];
      
      if(element.path == prevPath.location.state.prevPath){
        // matched path, now determine which neighbor it is:
        if(element.direction == 'next'){
          initString = "enterLeft";
        }else if(element.direction == 'previous'){
          initString = "enterRight";
        }
      }
    }
  }

  
  return (
    <motion.div id="activities-10" className="content-block full-height"
      variants={pageTransitions}
      initial={initString}
      animate="visible"
      exit={whereAreWeGoing}
    >

    <div className="billboard mb-4">
      <img src={lang.billboard_activities_10}/>
      <div className={"b-caption "+lang.credit_activities_10.color}>{lang.credit_activities_10.text}</div>      
    </div>
    {/* <img className="icon-round-large" src={lang.icon_activities_10}/> */}
    <svg version="1.1" id="d-icon-tech-advice" className="icon-round-large" x="0px" y="0px" viewBox="0 0 36.9 36.9" >
        <path className="blue" d="M18.5,36.9c10.2,0,18.4-8.2,18.4-18.4C36.9,8.3,28.6,0,18.5,0C8.3,0,0,8.3,0,18.4C0,28.6,8.3,36.9,18.5,36.9
          C18.5,36.9,18.5,36.9,18.5,36.9z"/>
        <polygon className="white" points="29.6,20.2 29.6,8.2 13.5,8.2 13.5,12.3 22.2,12.3 22.2,20.2 22.5,20.2 22.5,20.2 23.8,20.2 27.3,23.2 
          27.3,20.2 29.6,20.2 "/>
        <polygon className="white" points="21.3,25.9 21.3,13.1 6.2,13.1 6.2,25.9 8.3,25.9 8.3,28.9 11.8,25.9 "/>
        <path className="blue d-icon-cog" d="M17,19.1c0,0.7,0.5,1.3,1.2,1.4c-0.1,0.2-0.1,0.3-0.2,0.5c-0.2-0.1-0.5-0.2-0.7-0.2c-0.8,0-1.4,0.6-1.4,1.4
          c0,0.3,0.1,0.6,0.2,0.8c-0.2,0.1-0.5,0.2-0.7,0.3c-0.1-0.8-0.8-1.3-1.6-1.2c-0.7,0.1-1.2,0.6-1.2,1.2c-0.2-0.1-0.5-0.2-0.7-0.3
          c0.4-0.7,0.2-1.5-0.4-2c-0.5-0.3-1-0.3-1.5,0c-0.1-0.2-0.2-0.4-0.2-0.6c0.8-0.2,1.2-1,1-1.8c-0.1-0.5-0.5-0.8-1-1
          c0.1-0.2,0.2-0.4,0.3-0.6c0.7,0.3,1.6,0.1,1.9-0.7c0.2-0.4,0.2-0.9,0-1.3c0.2-0.1,0.5-0.2,0.8-0.3c0.2,0.8,1,1.2,1.8,1
          c0.5-0.1,0.8-0.5,1-1c0.2,0.1,0.4,0.2,0.6,0.3c-0.4,0.7-0.2,1.5,0.5,2c0.5,0.3,1.1,0.3,1.5,0c0.1,0.2,0.2,0.4,0.3,0.6
          C17.5,17.8,17,18.4,17,19.1z"/>
        <path className="white" d="M15.7,19.1c0-1-0.8-1.7-1.7-1.7c-1,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7c0,0,0,0,0,0
          C14.9,20.9,15.7,20.1,15.7,19.1z"/>
        </svg>

    <h1 className={"mb-0p5"+ current === 'en' ? ' d-narrow-spacing': ''}>{lang.title_activities_10}</h1>
    <h2>{lang.subtitle_activities_10}</h2>

    <div dangerouslySetInnerHTML={{__html: lang.text_activities_10}}></div>
    {/* <style>.cls-1{fill:#002b55;}.cls-2{fill:#fff;}</style> */}
    <svg version="1.1" id="technical-advice" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 455 281.2">
<circle className="st0" cx="149.2" cy="123.2" r="61.3"/>
<path className="st1" d="M217.9,120.4l9.4,0c-0.4-10.5-2.9-20.8-7.4-30.3l4.9-10.6l-11.7-1c-5.9-8.4-13.4-15.5-22-21.1l-4.7,8.2
	C205.2,77.6,216.9,98,217.9,120.4z"/>
<path className="st1" d="M149.3,36.4l-6.7,9.6c-10.4,0.9-20.4,3.8-29.6,8.7l4.7,8.2c19.8-10.2,43.4-10.3,63.2,0l4.7-8.2
	c-9.2-4.8-19.3-7.8-29.6-8.6L149.3,36.4z"/>
<path className="st1" d="M85.4,78.4l-11.7,1L78.7,90c-4.4,9.4-7,19.7-7.4,30.1l9.4,0c1-22.2,12.8-42.6,31.4-54.6l-4.7-8.1
	C98.8,62.9,91.3,70,85.4,78.4z"/>
<path className="st1" d="M149,192.3c-11,0-21.8-2.6-31.6-7.7l-4.7,8.2c9.2,4.9,19.3,7.8,29.6,8.7l6.7,9.6l6.7-9.6
	c10.3-0.9,20.3-3.8,29.4-8.6l-4.7-8.2C170.6,189.7,159.9,192.3,149,192.3z"/>
<path className="st1" d="M217.7,127c-1,22.3-12.8,42.8-31.5,54.8l4.7,8.2c8.7-5.5,16.2-12.7,22.1-21.1l11.6-1l-4.9-10.6
	c4.4-9.5,6.9-19.8,7.4-30.2L217.7,127z"/>
<path className="st1" d="M80.7,127l-9.4,0c0.4,10.5,2.9,20.9,7.4,30.4L73.8,168l11.7,1c5.9,8.4,13.3,15.5,21.9,21l4.7-8.2
	C93.4,169.7,81.7,149.3,80.7,127z"/>

<text x={lang.svg_labels.technicaladvice.p1.x} y={lang.svg_labels.technicaladvice.p1.y} className="blue bold white">{lang.svg_labels.technicaladvice.l1}</text>
<text x={lang.svg_labels.technicaladvice.p2.x} y={lang.svg_labels.technicaladvice.p2.y} className="blue bold white">{lang.svg_labels.technicaladvice.l2}</text>
<text x={lang.svg_labels.technicaladvice1.p1.x} y={lang.svg_labels.technicaladvice1.p1.y} className="blue bold">{lang.svg_labels.technicaladvice1.l1}</text>
<text x={lang.svg_labels.technicaladvice2.p1.x} y={lang.svg_labels.technicaladvice2.p1.y} className="blue bold">{lang.svg_labels.technicaladvice2.l1}</text>
<text x={lang.svg_labels.technicaladvice2.p2.x} y={lang.svg_labels.technicaladvice2.p2.y} className="blue bold">{lang.svg_labels.technicaladvice2.l2}</text>
<text x={lang.svg_labels.technicaladvice3.p1.x} y={lang.svg_labels.technicaladvice3.p1.y} className="blue bold">{lang.svg_labels.technicaladvice3.l1}</text>
<text x={lang.svg_labels.technicaladvice4.p1.x} y={lang.svg_labels.technicaladvice4.p1.y} className="blue bold">{lang.svg_labels.technicaladvice4.l1}</text>
<text x={lang.svg_labels.technicaladvice5.p1.x} y={lang.svg_labels.technicaladvice5.p1.y} className="blue bold">{lang.svg_labels.technicaladvice5.l1}</text>
<text x={lang.svg_labels.technicaladvice6.p1.x} y={lang.svg_labels.technicaladvice6.p1.y} className="blue bold">{lang.svg_labels.technicaladvice6.l1}</text>
</svg>

<div className="logos-5col">
  <img src="/logos/dvgw-sc.svg"/>
  <img src="/logos/iww.svg"/>
  <img src="/logos/dvgw-ebi.svg"/>
  <img src="/logos/tzw.svg"/>
  <img src="/logos/dbi-gut.svg"/>
</div>


    </motion.div>
  )
}

export default ActivitiesTechnicalAdvice;