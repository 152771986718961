import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { ThemeContext } from '../../contexts/ThemeContext';

const SubpageButtonRight = ({location}) => {
  const { subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext);
  const currentPage = subpagelinks[location.pathname];

  if(currentPage){
    return ( 
      <div className={ 'd-mid-right ' + currentPage[0].position }>
        <div className="subpage-button subpage-button-right subpage-button-green">
          { currentPage.map(function(item) {
            return (
              <NavLink key={item.id} className={(item.direction == 'next' || item.direction == 'up' || item.direction == 'home') ? 'btn-lg' : 'btn-sm' + (item.direction == 'none' ? ' btn-sm-none' : '')} to={{
                pathname: item.path,
                state: {prevPath: location.pathname, direction: 'forward'}
              }}>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  viewBox="0 0 105 105" className={'button-round-arrow'+ (item.direction == 'up' ? ' up' : '')+ (item.direction == 'home' ? ' up' : '')} onClick={ () => {
                    if(item.direction == 'previous'){
                      toggleWhereAreWeGoing('exitRight');
                    }else if(item.direction == 'next'){
                      toggleWhereAreWeGoing('exitLeft');
                    }else if(item.direction == 'up'){
                      toggleWhereAreWeGoing('hidden');
                    }else if(item.direction == 'home'){
                      toggleWhereAreWeGoing('hiddenDelayed');
                    }
                  }}>
                  <defs>
                    <linearGradient id="green-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                      <stop offset="0%" style={{stopColor: '#00a8bf', stopOpacity: 1}} />
                      <stop offset="100%" style={{stopColor: '#8bc400', stopOpacity: 1}} />
                    </linearGradient>
                    <linearGradient id="blue-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                      <stop offset="0%" style={{stopColor: '#00a8bf', stopOpacity: 1}} />
                      <stop offset="100%" style={{stopColor: '#8bc400', stopOpacity: 1}} />
                    </linearGradient>
                    <linearGradient id="red-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" style={{stopColor: '#00a8bf', stopOpacity: 1}} />
                      <stop offset="100%" style={{stopColor: '#8bc400', stopOpacity: 1}} />
                    </linearGradient>
                    <linearGradient id="yellow-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" style={{stopColor: '#8bc400', stopOpacity: 1}} />
                      <stop offset="100%" style={{stopColor: '#00a8bf', stopOpacity: 1}} />
                    </linearGradient>
                    <linearGradient id="stroke-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" style={{stopColor: '#ffffff', stopOpacity: 1}} />
                      <stop offset="100%" style={{stopColor: '#ffffff', stopOpacity: 1}} />
                    </linearGradient>
                  </defs>
                  <g>
                    <circle fill={
                      item.direction == 'previous' ? '#fff' :
                      item.direction == 'up' ? 'url(#green-gradient)' : 
                      item.direction == 'next' ? 'url(#red-gradient)' : 
                      'url(#blue-gradient)'} cx="52.5" cy="52.5" r="52.5"/>
                    <polyline className="st2" points="40.4,26.7 66.4,52.2 40.4,77.8" stroke={item.direction == 'previous' ? 'url(#yellow-gradient)' : 'url(#stroke-gradient)'}/>
                  </g>
                </svg>
              </NavLink>
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-mid-right">
        <div className="subpage-button">
          &nbsp;
        </div>
      </div>
    );
  }
}

export default SubpageButtonRight;